import { AuthAction, useUser, withUser } from 'next-firebase-auth'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form'
import Button from 'react-bootstrap/Button'
import InputGroup from 'react-bootstrap/InputGroup'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import axios from 'axios'
import * as auth from 'firebase/auth'
import 'react-phone-number-input/style.css'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import styles from '../../styles/pages/SignIn.module.css'
import { fetchSEO } from '../../lib/blog/api'
import Seo from '../../components/blog/seo'

const trySignInWithEmailLink = async (authUser) => {
  // TODO: This seems to be loaded twice when opening the sign-in-with-email-link
  // URL. The second time, it asks for email confirmation (because localStorage
  // is cleared by then), but we have mitigated the issue by checking
  // !authUser.id too.
  if (typeof window !== 'undefined' && auth.isSignInWithEmailLink(auth.getAuth(), window.location.href)) {
    if (authUser.id) {
      // router.push('/') // Clear the URL parameters
      return
    }

    let email = window.localStorage.getItem('emailForSignIn')
    if (!email) {
      // User opened the link on a different device. To prevent session fixation
      // attacks, ask the user to provide the associated email again:
      email = window.prompt('Please provide your email for confirmation')
    }

    try {
      // Sign in
      await auth.signInWithEmailLink(auth.getAuth(), email, window.location.href)
      window.localStorage.removeItem('emailForSignIn')
      window.location.href = '/app'
    } catch (error) {
      console.error(error)
    }
  }
}

const LoginWithEmail = ({backButton}) => {
  const router = useRouter()
  const [email, setEmail] = useState('')
  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase().trim())
    setStatus({
      message: null,
      isError: false
    })
  }

  const [validationIsOn, setValidationIsOn] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [status, setStatus] = useState({
    message: null,
    isError: false
  })

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (isSubmitting) {
      return
    }

    setStatus({
      message: null,
      isError: false
    })

    // Validate form
    if (!e.currentTarget.checkValidity()) {
      setValidationIsOn(true)
      setIsSubmitting(false)
      return
    }

    // Submit
    setIsSubmitting(true)
    try {
      // If there is a destination query parameter, then maintain it through the magic link
      let targetUrl = window.location.protocol + '//' + window.location.host + '/app/signin'
      if (router.query.destination) {
        // https://owasp.org/www-project-web-security-testing-guide/v41/4-Web_Application_Security_Testing/11-Client_Side_Testing/04-Testing_for_Client_Side_URL_Redirect
        const allowedHosts = ['localhost:3000', 'ivyflip.com', 'www.ivyflip.com', 'dev.ivyflip.com', 'app.ivyflip.com']
        const isAllowed = allowedHosts.indexOf(new URL(router.query.destination).host) > -1
        if (isAllowed) {
          targetUrl += `?destination=${encodeURIComponent(router.query.destination)}`
        } else {
          console.warn(`Redirect destination host must be one of ${allowedHosts.join(', ')}`)
        }
      }

      // Send the magic link via email
      await axios.post('/api/signinLink', {
        email,
        target_url: targetUrl
      })
      window.localStorage.setItem('emailForSignIn', email)

      setStatus({
        message: 'Check your email for a sign-in link.',
        isError: false
      })
    } catch (error) {
      setStatus({
        message: 'Something went wrong: ' + error.message,
        isError: true
      })
    } finally {
      setIsSubmitting(false)
      setValidationIsOn(false)
    }
  }

  return (
    <Form
      noValidate validated={validationIsOn}
      onSubmit={handleSubmit}
      className={styles.form}
    >
      <InputGroup hasValidation className='mb-3'>
        <Form.Control
          type='email'
          required
          placeholder='Enter email address'
          value={email} onChange={handleEmailChange}
        />
        <Form.Control.Feedback type='invalid'>
          Please enter a valid email address.
        </Form.Control.Feedback>
      </InputGroup>
      <div className='d-flex flex-column flex-md-row gap-2 justify-content-between'>
        <Button
          type='submit' variant='primary'
          disabled={isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
        {backButton}
      </div>
      {status.message && (
        <Form.Text className={status.isError ? 'text-danger' : 'text-success'}>
          {status.message}
        </Form.Text>
      )}
    </Form>
  )
}

const LoginWithPhone = ({backButton}) => {
  const [validationIsOn, setValidationIsOn] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const router = useRouter()
  const [status, setStatus] = useState({
    message: null,
    isError: false
  })

  // Handle step 1: sending the phone number to the server to initiate authentication
  const [phoneNumber, setPhoneNumber] = useState('')
  useEffect(() => setStatus({
    message: null,
    isError: false
  }), [phoneNumber])

  const recaptchaFieldId = 'recaptcha-container'
  const setupRecaptcha = () => {
    // Set up the reCAPTCHA verifier on client side (button click is only client side)
    window.recaptchaVerifier = new auth.RecaptchaVerifier(
      auth.getAuth(),
      recaptchaFieldId,
      {
        size: 'invisible',
        callback: () => {
        },
        'expired-callback': () => {
        },
        'error-callback': (error) => {
          console.log(error)
        }
      }
    )
  }

  const resetRecaptcha = () => {
    window.recaptchaVerifier.clear()
    window.recaptchaVerifier = null
  }

  const onRecaptchaContainerChange = el => {
    if (typeof window !== 'undefined' && el) {
      setupRecaptcha()
    }
  }

  const [authConfirmationResult, setAuthConfirmationResult] = useState()
  const handleSubmitPhoneNumber = async (e) => {
    e.preventDefault()
    if (isSubmitting) {
      return
    }

    setStatus({
      message: null,
      isError: false
    })

    // Validate form
    if (!e.currentTarget.checkValidity()) {
      setValidationIsOn(true)
      setIsSubmitting(false)
      return
    }

    // Submit
    setIsSubmitting(true)

    // Send a verification code to the user's phone
    try {
      if (!window.recaptchaVerifier) {
        setupRecaptcha()
      }
      const appVerifier = window.recaptchaVerifier
      if (!isValidPhoneNumber(phoneNumber)) {
        return setStatus({
          message: 'Please enter a valid phone number.',
          isError: true
        })
      }

      const rateLimitWindow = 300 // 5 minutes
      const signInAttempts = JSON.parse(window.localStorage.getItem('sa')) ?? { count: 0, lastRequestTimestamp: 0 }
      const now = +new Date()
      if (now - signInAttempts.lastRequestTimestamp > rateLimitWindow * 1000) {
        signInAttempts.count = 0
      }
      signInAttempts.count++
      signInAttempts.lastRequestTimestamp = now
      window.localStorage.setItem('sa', JSON.stringify(signInAttempts))
      if (signInAttempts.count >= 5) {
        return setStatus({
          message: 'Too many attempts. Please try again later.',
          isError: true
        })
      }

      const response = await auth.signInWithPhoneNumber(auth.getAuth(), phoneNumber, appVerifier)
      setAuthConfirmationResult(response)
      setPhoneNumber('')

      setStatus({
        message: 'Check your SMS for a verification code.',
        isError: false
      })
    } catch (error) {
      console.error(error)
      setAuthConfirmationResult(null)

      let message = 'Something went wrong: ' + error.message
      if (error.message.includes('TOO_SHORT')) {
        message = 'Phone number is too short, please enter the correct one.'
      }
      if (
        error.message.includes('INVALID_PHONE_NUMBER') ||
        error.message.includes('auth/invalid-phone-number')
      ) {
        message = 'Please enter a valid phone number.'
      }

      setStatus({
        message,
        isError: true
      })
    } finally {
      setIsSubmitting(false)
      setValidationIsOn(false)
    }
  }

  // Handle step 2: sending the verification code to the server to finish authentication
  const [verificationCode, setVerificationCode] = useState('')
  const handleVerificationCodeChange = (e) => {
    setVerificationCode(e.target.value)
    setStatus({
      message: null,
      isError: false
    })
  }

  const handleSubmitVerificationCode = async (e) => {
    e.preventDefault()
    if (isSubmitting) {
      return
    }

    setStatus({
      message: null,
      isError: false
    })

    // Validate form
    if (!e.currentTarget.checkValidity()) {
      setValidationIsOn(true)
      setIsSubmitting(false)
      return
    }

    // Submit
    setIsSubmitting(true)

    // Validate the verification code entered by the user
    try {
      const credential = auth.PhoneAuthProvider.credential(authConfirmationResult.verificationId, verificationCode)
      await auth.signInWithCredential(auth.getAuth(), credential)
      if (typeof window !== 'undefined') {
        window.location.reload()
      } else {
        router.push('/app')
      }
    } catch (error) {
      console.error(error)
      setStatus({
        message: 'Verification code was not accepted. Please try again.',
        isError: true
      })
    } finally {
      setIsSubmitting(false)
      setValidationIsOn(false)
    }
  }

  // Go 'back' to step 1 (phone number entry)
  const handleCancel = async () => {
    setStatus({
      message: null,
      isError: false
    })
    setAuthConfirmationResult()
    setPhoneNumber()
    resetRecaptcha()
  }

  if (authConfirmationResult) {
    // Step 2: Enter verification code
    return (
      <Form
        noValidate validated={validationIsOn}
        onSubmit={handleSubmitVerificationCode}
        className={styles.form}
      >
        <InputGroup hasValidation className='mb-3'>
          <Form.Control
            type='number'
            required
            value={verificationCode}
            placeholder='Enter the verification code'
            autoFocus={true}
            onChange={handleVerificationCodeChange}
          />
          <Form.Control.Feedback type='invalid'>
            Please enter the correct verification code.
          </Form.Control.Feedback>
        </InputGroup>
        <div className='d-flex flex-column flex-md-row gap-2 justify-content-between'>
          <Button
            type='submit' variant='primary'
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
          <Button
            variant='outline-primary'
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            Back
          </Button>
        </div>
        {status.message && (
          <Form.Text className={status.isError ? 'text-danger' : 'text-success'}>
            {status.message}
          </Form.Text>
        )}
      </Form>
    )
  } else {
    // Step 1: Enter phone number
    return (
      <>
      <div
        ref={onRecaptchaContainerChange}
        id={recaptchaFieldId}
        style={{ position: 'relative', zIndex: 3 }}
      />
      <Form
        noValidate validated={validationIsOn}
        onSubmit={handleSubmitPhoneNumber}
        className={styles.form}
      >
        <PhoneInput
          inputComponent={Form.Control}
          defaultCountry='US'
          // countryOptionsOrder={['US', 'CA']}
          placeholder='Enter phone number'
          value={phoneNumber}
          onChange={setPhoneNumber}
          className='mb-3'
        />
        <div className='d-flex flex-column flex-md-row gap-2 justify-content-between'>
          <Button
            type='submit'
            variant='primary'
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Submitting...' : 'Submit'}
          </Button>
          {backButton}
        </div>
        {status.message && (
          <Form.Text className={status.isError ? 'text-danger' : 'text-success'}>
            {status.message}
          </Form.Text>
        )}
      </Form>
      </>
    )
  }
}

const SignIn = ({ seo }) => {
  const authUser = useUser()
  const router = useRouter()

  // Check if we're handling a login redirect link for email link auth
  useEffect(() => {
    trySignInWithEmailLink(authUser, router)
  }, [])

  const [signInMethod, setSignInMethod] = useState('phone')

  return (
    <>
      <Seo seo={seo} />

    <Row className='mb-2'>
      <Col xs={12}>
        <div className='p-3 px-md-4 py-md-4 p-lg-5 bg-light br-3'>
          <Row>
            <Col xs={12} md={{ span: 10, offset: 1 }} xl={{ span: 8, offset: 2 }}>
              <div className='px-0 px-sm-2 p-lg-5'>
                <h1 style={{fontWeight: 600}}>
                  Sign in to Ivy
                </h1>
                <p className='lead'>
                  Create a new account or sign into your existing account below.
                </p>
              </div>
              <div className='px-0 px-sm-2 px-lg-5 pt-5 pt-lg-0 pb-0'>
                {signInMethod === 'email' && (
                  <LoginWithEmail backButton={
                    <Button
                      variant='outline-primary'
                      onClick={() => setSignInMethod('phone')}
                    >
                      Use phone number
                    </Button>
                  } />
                )}
                {signInMethod === 'phone' && (
                  <LoginWithPhone backButton={
                    <Button
                      variant='outline-primary'
                      onClick={() => setSignInMethod('email')}
                    >
                      Use email address
                    </Button>
                  } />
                )}
                {!signInMethod && (
                  <>
                    <Button
                      className='w-100 mb-3'
                      onClick={() => setSignInMethod('phone')}
                      variant='outline-primary'
                    >
                      Use phone number
                    </Button>
                    <Button
                      className='w-100 mb-3'
                      onClick={() => setSignInMethod('email')}
                      variant='outline-primary'
                    >
                      Use email address
                    </Button>
                  </>
                )}

                <div className='mt-5'>
                  If you need help, please contact us
                  at <a href='mailto:hello@ivyflip.com'>hello@ivyflip.com</a>.
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
    </>
  )
}

export async function getStaticProps () {
  const seo = await fetchSEO('signin')
  return {
    props: { seo },
    revalidate: 60 * 60 * 24
  }
}

export default withUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP
})(SignIn)
